@tailwind base;
@tailwind components;
@tailwind utilities;



main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

code {
  background-color: rgb(30, 30, 30) !important;
}
